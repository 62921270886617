@import '../../variables';

:root {
  --blue-box-warning: #{$blue-box-warning};
  --blue-text-warning: #{$blue-text-warning};
  --grey-faded: #{$grey-faded};
  --grey-heavy: #{$grey-heavy};
  --mustard-heavy: #{$mustard-heavy};
  --mustard-light: #{$mustard-light};
  --orange-strong: #{$orange-strong};
  --red: #{$red};
  --white: #{$white};
}

body {
  background-color: $grey-super-light;
}

.container_form {
  position: relative;
  padding-bottom: 10vh;
  min-height: 100vh;
}

.header {
  height: 7.5vh;
  background-color: $orange-strong;
  padding: 2px;
}

.logo {
  height: 100%;
  width: 17vw;
  background-color: #FAFAFA;
  border-radius: 0 4vh 4vh 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-breakpoint) {
    width: 45vw;
  }

  @media ((min-width: $sm-breakpoint) and (max-width: $md-breakpoint)) {
    width: 37vw;
  }

  @media ((min-width: $md-breakpoint) and (max-width: $lg-breakpoint)) {
    width: 32vw;
  }
}

.logo img {
  width: 65%;

  @media (max-width: $sm-breakpoint) {
    width: 65%;
  }

  @media ((min-width: $sm-breakpoint) and (max-width: $md-breakpoint)) {
    width: 60%;
  }
}

.footer_form {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 75%;
  background-image: url("../../static/svgs/Footer-bg.svg");
}

.country {
  position: absolute;
  top: 5vh;
  right: 5vw;
  width: 11vw;
}

.country__info {
  display: flex;
  justify-content: flex-end;
}

.country__info > img {
  margin-right: 5px;
}

.country__info > span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $black;
}
.country__select {
  height: 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $blue-king;
  text-align: right;
  margin-top: 2px;
}

.spinner {
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-bottom-color: $transparent;
  border-left-color: $transparent;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.fieldset {
  margin: 30px 0 8px 0;
}

.fieldset legend {
  font-size: 16px;
  font-weight: 700;
}

.fieldset label {
  font-size: 14px;
}

.spinner-overlay {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  z-index: 9998;
}

.overlay-element {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.dropdown-item:active {
  background-color: $grey-bg-dropdown-option;
}

.warning-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
