@import '../../variables';

.progressBar {
  text-align: center;
  margin: 0 auto;
  width: 500px;

  @media (max-width: $md-breakpoint) {
    display: none;
  }
}

.dot {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: $grey-light;
  border-radius: 50%;
  margin: 30px;
  position: relative;
}

.dot::after {
  content: "";
  position: absolute;
  height: 2px;
  top: 10px;
  width: 30px;
  left: 24px;
  background: $grey-light;
}

.dot::before {
  content: "";
  position: absolute;
  height: 2px;
  top: 10px;
  width: 30px;
  right: 24px;
  background: $grey-light;
}

#firstDot {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: $green;
  border-radius: 50%;
  margin: 30px;
  position: relative;
}

#firstDot::after {
  content: "";
  position: absolute;
  height: 2px;
  top: 10px;
  width: 30px;
  left: 24px;
  background: $green;
}

#lastDot {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: $grey-light;
  border-radius: 50%;
  margin: 30px;
  position: relative;
}

#lastDot::before {
  content: "";
  position: absolute;
  height: 2px;
  top: 10px;
  width: 30px;
  right: 24px;
  background: $grey-light;
}

.stepper_count {
  position: relative;
  top: -60px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.circle-completed {
  background-color: $orange-strong;
}
