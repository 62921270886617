@import '../../../variables';

.accordion_navigation {
    display: block;
    text-align: right;
    justify-content: space-around;
    margin-top: 50px;
}
.input_button_simple {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    text-align: right;
    color: $grey-dark-alt;
    border-radius: 0;
    border-bottom: 1px solid $grey-dark-alt;
    padding: 0;
}

.continue_button_disabled {
    color: $grey-faded;
    border-bottom: 1px solid $grey-faded;
}

.continue_button_disabled:hover {
    color: $grey-faded;
    border-bottom: 1px solid $grey-faded;
}
