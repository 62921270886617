@import '../../../variables';

.accordion-item {
    margin-bottom: 20px !important;
    background-color: $white;
    border: 0.5px solid $grey-border;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.accordion-item:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.accordion-item:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.accordion-header {
    background-color: $white;
    border: 0.5px solid $grey-border;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.accordion_title h1 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}

.accordion_title h2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-overflow: ellipsis;
}

.accordion-button {
    background-color: $white;
    border-radius: 10px;
    white-space: normal;
}

.accordion-button.collapsed {
    background-color: $white;
    border-radius: 10px;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
    background-color: $white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.accordion_title {
    padding: 0px 150px;
    overflow: hidden;
}

.accordion-body {
    padding-top: 0;
    padding-bottom: 30px;

    @media (max-width: $sm-breakpoint) {
        padding: 0 0 30px;
    }
}

.accordion-item-disabled {
    pointer-events: none;
}

.accordion-item-disabled h1 {
    color: $grey-faded;
}

.accordion-item-disabled h2 {
    color: $grey-faded;
}

.accordion-item-enabled h1 {
    color: $grey-dark;
}

.accordion-item-enabled h2 {
    color: $grey-dark;
}

.accordion-header-left {
    float: left;
    width: 70%;
}

.accordion-header-right {
    float: right;
    width: 30%;
    padding-top: 20px;
    padding-right: 5px;
}

.accordion-header-notification {
    color: $red;
    font-size: 12px;
    background-position: right;
    background-repeat: no-repeat;
    height: 20px;
    background-size: 20px;
    padding-top: 2px;
    background-image: url("../../../static/svgs/alert-red.svg") !important;
}