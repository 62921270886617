@import '../../../variables';

.form_wrapper {
    padding: 20px 150px;
    font-family: "Lato", "sans-serif";

    @media (max-width: $sm-breakpoint) {
        padding: 20px;
    }

    @media ((min-width: $sm-breakpoint) and (max-width: $lg-breakpoint)) {
        padding: 20px 45px;
    }

    @media ((min-width: $lg-breakpoint)) {
        padding: 20px 90px;
    }
}
.input_plain {
    border-top: 0;
    border-left: 2px;
    border-right: 0;
    padding-left: 0;
    border-radius: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-color: $grey-medium;
    background-size: 15px;
    background-position: right 5px top 10px;
    background-repeat: no-repeat;
}

.input_plain.search-icon {
    background-image: url("../../../static/svgs/icon-seach.svg");
}

.input_plain.is-invalid {
    background-size: auto !important;
    background-position: right 5px top 3px !important;
    background-image: url("../../../static/svgs/alert-red.svg") !important;
}

.input_plain option {
    background-color: $grey-bg-input-info;
    color: $grey-dark;
    font-size: 16px;
    padding: 10px;
}

.rbt-aux {
    display: none;
}

.input_plain:focus {
    border-color: $orange-strong;
    box-shadow: none;
}

.span_mandatory {
    color: $red-alert;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    letter-spacing: 5px;
}

.has_error {
    border-color: $red-alert;
}
.label_error {
    color: $red-alert;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
}
.error_close_button {
    font-size: 20px;
    float: right;
    margin-top: -35px;
    border: 0;
    background: none;
    color: $red-alert;
}

.error_close_button img {
    margin-top: -20px;
}

.label_error_input_group {
    position: relative;
    top: -20px;
    border-color: $red-alert;
    border-top: 1px solid;
    z-index: 9;
}
.label_error_dropfile {
    position: relative;
    top: -25px;
    border-color: $red-alert;
    border-top: 1px solid;
    z-index: 9;
}
.input_label_plain {
    margin-top: 30px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: $black;
}

.input_label_plain_country {
    float: left;
    font-size: 14px;
    padding-left: 40px;
    font-style: normal;
}

.input_button_plain {
    border-radius: 24px;
    background-color: $orange-plain-medium;
    border-color: $orange-plain-medium;
    color: $white;
    font-weight: 700;
    padding: 10px;
    width: 156px;
    height: 45px;
}
.field_group_inline_left {
    float: left;
    padding: 0;
}
.field_group_inline_right {
    float: right;
    padding: 0;
}
.thumbnail_dropdown {
    text-align: left;
    color: $black-faded !important;
    background-color: $transparent !important;
}
.menu-dropdown {
    padding: 0;
}

.dropdown-option:hover {
    border-left: solid 3px $orange-dropdown-border;
}

.dropdown-option:first-child {
    border-top-left-radius: 4px;
}

.dropdown-option:last-child {
    border-bottom-left-radius: 4px;
}

.dropdown-option:active {
    color: var(--bs-dropdown-link-color);
}

.thumbnail_dropdown_item:hover, .dropdown-option:hover {
    background-color: $grey-bg-dropdown-option !important;
}
.width_10 {
    width: 10%;
}
.width_15 {
    width: 15%;
}
.width_20 {
    width: 20%;
}
.width_25 {
    width: 25%;
}
.width_30 {
    width: 30%;
}
.width_35 {
    width: 35%;
}
.width_40 {
    width: 40%;
}
.width_45 {
    width: 45%;
}
.width_50 {
    width: 50%;
}
.width_55 {
    width: 55%;
}
.width_60 {
    width: 60%;
}
.width_65 {
    width: 65%;
}
.width_70 {
    width: 70%;
}
.width_75 {
    width: 75%;
}
.width_80 {
    width: 80%;
}
.width_85 {
    width: 85%;
}
.width_90 {
    width: 90%;
}
.width_95 {
    width: 90%;
}
.width_100 {
    width: 100%;
}
.label_description {
    display: block;
    font-size: 14px;
    color: $grey-heavy;
    margin-top: -10px;
}
.boxed_label {
    padding: 20px 20px 20px 50px;
    background: $grey-label;
}
.rdb_label {
    display: block;
    margin-bottom: 10px;
}

.cb_label_text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 10px;
    margin-bottom: 0;
    color: $grey-dark;
    vertical-align: middle;
}

.rdb_label_text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 25px;
    margin-top: -25px;
    margin-bottom: 0;
    color: $grey-dark
}

.rdb_label_link {
    color: $grey-dark
}

.rdb_orange:checked {
    background-color: $orange-strong !important;
    border-color: $orange-strong !important;
}

.cb_checkbox {
    width: 24px;
    height: 24px;
    border-radius: 0 !important;
    box-sizing: border-box;
}

.cb_checkbox:checked {
    padding: 5px;
    border-radius: 0;
    background-color: $white;
    border-color: $orange-strong !important;
    background-image: url("../../../static/svgs/check.svg") !important;
    background-size: 75%;
}

.option_label_description {
    display: block;
    margin-left: 25px;
    font-size: 12px;
    color: $grey-info-line;
}
.image-upload {
    width: 100%;
    height: 65px;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    background: $grey-label;
    color: $grey-info-line;
    overflow: hidden;
    padding-top: 20px;
}
.item-wrapper form img {
    margin-bottom: 20px;
    width: auto;
    height: auto;
    max-height: 400px;
    width: auto;
    border-radius: 5px;
    overflow: hidden
}
.image-upload img {
    height: 100% !important;
    width: auto !important;
    border-radius: 0px;
    margin: 0 auto
}
.image-upload i {
    font-size: 6em;
    color: $grey-bg-input-box;
}
.image-upload input {
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0
}
.dropfilecontrol_label_description_sub {
    font-weight: bold;
    color: $orange-strong;
}
.hidden {
    display: none;
}
.dropdown-toggle::after {
    content: none;
}
.field_group_label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $grey-dark;
}

.padding_top_5 {
    padding-top: 5px;
}

.autofill {
    margin-top: 40px;
}

.file-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $grey-super-light;
    border: 2px dashed $grey-bg-input-box;
    padding-top: 20px;
    border-radius: 7px;
}
.drag img, .drag .title {
    opacity: 0.5;
}

.file-container.drag {
    border-color: $orange-strong !important;
}

.inline-label {
    vertical-align: middle;
    padding: 15px 0 10px;
}

.file-container img {
    height: 50px;
    width: auto;
}

.file-container .title {
    margin-bottom: 0;
    vertical-align: middle;
    height: auto;
    font-size: 13px;

}

.file-container .button-title {
    font-size: 13px;
    color: $grey-dark-alt;
    border-radius: 0;
    border-bottom: 1px solid $grey-dark-alt;
    padding: 0;
    margin-right: 5px;
}

.file-container .button-title:active {
    border-color: $grey-super-light !important;
}

.file-container .title, .file-container .button-title {
    color : $blue-heavy
}

.file-container .description {
    font-size: 10px;
    color: $grey-input-box-label;
}

.file-info {
    background-color: $grey-bg-input-info;
    margin-top: 15px;
    height: 29px;
    font-size: 13px;
    display: flex;
    padding: 0 10px;
    vertical-align: middle;
    align-items: center;
    justify-content: space-between;
}

.file-info span {
    justify-content: flex-start;
}

.file-info img {
    justify-content: flex-end;
    height: 13px;
    width: auto;
}

.btn {
    margin: 20px 0;
}

.btn-light {
    display: flex;
    color: $grey-heavy;
    border-color: $grey-heavy;
    border-radius: 30px;
    border-width: 2px;
    padding: 6px 24px;
}

.btn-light img {
    margin-left: 7px;
    align-self: center;
}

.dropdown-control {
    margin: 0;
    height: 35px;
    background-color: $white !important;
    color: $black;
    text-align: initial;
    border-color: $grey-medium;
}

.dropdown-control:hover {
    color: $black;
    border-color: $orange-strong;
}
