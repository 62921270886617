@import '../../variables';

.navigation {
  display: block;
  text-align: right;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;
}

.input_button_next {
  border-radius: 24px;
  background-color: $orange-strong;
  color: $white;
  font-weight: 700;
  padding: 10px;
  width: 275px;  
  font-size: 19px;
  font-family: 'Lato Bold';

  @media (max-width: $sm-breakpoint) {
    width: 100%;
  }
}

.input_button_next:hover {
  color: $white;
  background-color: $orange-shadow;
}

.input_button_prev {
  border-radius: 24px;
  background-color: $white;
  border: 1.5px solid $grey-dark;
  color: $black;
  font-weight: 300;
  padding: 10px;
  width: 156px;
  margin-right: 30px;
  height: 45px;

  @media (max-width: $sm-breakpoint) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.input_button_prev:hover {
  color: $black;
  background-color: $grey-super-light;
}

.submit_button_disbled {
  background: $grey-faded;
  color: $black-faded;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Lato';
}

.submit_button_disbled:hover {
  background: $grey-faded;
  color: $black-faded;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Lato';
}