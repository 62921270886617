$black: #131313;
$black-faded: #636363;
$black-subtittle: #6c757d;
$blue-box-warning: #e6f2ff;
$blue-heavy: #1e364b;
$blue-king: #0052cc;
$blue-link: #0c2941;
$blue-text-warning: #7984c2;
$green: #86b938;
$grey-bg-dropdown-option: #f2f2f2;
$grey-bg-input-box: #bbbbbb;
$grey-bg-input-info: #f1f1f1;
$grey-blue-medium: #a9b5c0;
$grey-dark: #333333;
$grey-dark-alt: #4a4a4a;
$grey-faded: #e1e1e1;
$grey-heavy: #495867;
$grey-info-line: #646464;
$grey-input-box-label: #8998a7;
$grey-label: #f7f7f7;
$grey-light: #dddddd;
$grey-medium: #acacac;
$grey-super-light: #fafafa;
$grey-border: #d1d1d1;
$mustard-heavy: #cb9605;
$mustard-light: #fff6dc;
$orange-dropdown-border: #41B800;
$orange-light-tittle-image-gradient-1: #ff9401;
$orange-light-tittle-image-gradient-2: #ff7401; 
$orange-medium: #ff9301;
$orange-plain-medium: #ff7100;
$orange-shadow: #AAD500;
$orange-strong: #41B800;
$red: #ff0000;
$red-alert: #d60303;
$transparent: transparent;
$white: #ffffff;

$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;
