@font-face {
  font-family: "Falabella Activa";
  src: url(static/fonts/FalabellaActiva-Regular.otf);
}

@font-face {
  font-family: "Lato";
  src: url(static/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Lato Bold";
  src: url(static/fonts/Lato-Bold.ttf);
}

body {
  margin: 0;
  font-family: "Falabella Activa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lato {
  font-family: "Lato";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
