@import '../../variables';

.content__fields {
  background: $white;
  padding: 10px 10px 50px 10px;
  border-radius: 5px;
  border: 0.5px solid $grey-light;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.content__fields_no_bg {
  padding: 0px 10px 0px 10px;
  border: 0.5px;
}

.form_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
  color: $grey-dark;

  @media (max-width: $sm-breakpoint) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  @media ((min-width: $sm-breakpoint) and (max-width: $md-breakpoint)) {
    margin-top: 18px;
    margin-bottom: 30px;
  }

  @media ((min-width: $md-breakpoint) and (max-width: $lg-breakpoint)) {
    margin-top: 25px;
    margin-bottom: 40px;
  }
}

.form_subtitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: $grey-dark;
}

.text_strong {
  font-weight: bold;
  font-size: 18px;
}

.text_center {
  text-align: center;
}
