@import '../../variables';

.content,
.multi_content {
  position: relative;
  z-index: 100;
}

.content {
  width: 60%;
  margin-left: 20vw;
  margin-top: 5vh;
  margin-bottom: 5vh;

  @media (max-width: $sm-breakpoint) {
    width: 90%;
    margin-left: 4vw;
    margin-top: 8vh;
  }
  
  @media ((min-width: $sm-breakpoint) and (max-width: $md-breakpoint)) {
    width: 80%;
    margin-left: 10vw;
    margin-top: 7vh;
  }

  @media ((min-width: $md-breakpoint) and (max-width: $lg-breakpoint)) {
    width: 70%;
    margin-left: 15vw;
    margin-top: 6vh;
  }

}

.multi_content {
  text-align: center;
  width: 80%;
  margin-left: 8vw;
  margin-top: 15vh;

  @media (max-width: $sm-breakpoint) {
    width: 90%;
    margin-left: 4vw;
    margin-top: 10vh;
  }
}

.content__info__line1 {
  text-align: center;
  font-family: "Lato";
  font-weight: 300;
  font-size: 27px;
  line-height: 20px;
  color: $grey-info-line;
}

.content__info__middle {
  display: flex;
  flex-direction: column;
}

.content__info__middle > span {
  text-align: center;
  font-family: "Lato";
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: $grey-info-line;
}

.content__info__line4 {
  text-align: center;
  font-family: "Lato";
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: $grey-info-line;
}

.content__info__line4 a {
  color: $grey-info-line;
}

.dropdown-menu.show {
  width: 100%;
}

.content_box {
  text-align: left;
  box-sizing: border-box;
  background-color: $white;
  border-radius: 10px;
  padding: 50px;

  @media (max-width: $sm-breakpoint) {
    padding: 20px;
  }
}

.content_box.lined {
  border: 1px solid $grey-blue-medium;
}

.content_box.shaded {
  border: 0.5px solid $grey-light;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.content_box h3 {
  box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: $grey-heavy;
  padding-bottom: 10%;
}

.content_box h1 {
  font-family: "Lato";
  font-size: 24px;
  line-height: 30px;
  display: flex;
  color: $black;
  justify-content: center;
  padding-bottom: 1vh;

  @media (max-width: $sm-breakpoint) {
    font-size: 20px;
  }
}

.content_plain {
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 50px;

  &.no-padding {
    padding-bottom: 0;
  }
  
  @media (max-width: $sm-breakpoint) {
    padding-bottom: 30px;
  }

  @media ((min-width: $sm-breakpoint) and (max-width: $md-breakpoint)) {
    padding-bottom: 40px;
  }
}

.content_plain h1 {
  font-weight: 700;
  font-size: 27px;
  line-height: 39px;
  color: $blue-heavy;
}

.content_plain h4 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: $grey-dark;
}

.form_footer {
  margin-top: 3rem;
  text-align: right;

  @media (max-width: $lg-breakpoint) {
    text-align: center;
  }
}

.content_info_bullet_list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.custom_bullet_marker {
  width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 10px;
    position: relative;
    top: 15px;
}

.bullet {
  margin-bottom: 1.5rem;
}

.content_info_bullet_list .bullet_icon {
  position: relative;
  width: 40px;
  left: -10px;
  top: 15px;
}

.content_info_bullet_list .bullet_img {
  width: 20px;
}

.content_info_bullet_list h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.content_info_bullet_list h6 {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: $grey-heavy;
}

.content_box_footer {
  margin-top: 15%;
}

.content_box_footer p {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: $blue-link;
}

.content_box_footer p a {
  color: $blue-link;
}

.content_box_centered {
  width: 80%;
  margin: auto;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid $grey-blue-medium;
  background-color: $white;
  border-radius: 10px;
  padding: 2% 0% 5% 0%;

  @media (max-width: $sm-breakpoint) {
    width: 95%;
  }
}

.content_box_centered .title_image {
  background: linear-gradient(
    178.74deg,
    $orange-light-tittle-image-gradient-1 19.82%,
    $orange-light-tittle-image-gradient-2 89.57%
  );
  width: 238.55px;
  height: 238.55px;
  margin: auto;
  border-radius: 150px;
  overflow: hidden;
}

.content_box_centered .title {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: $blue-heavy;
  margin-top: 30px;
}

.content_box_centered .sub_title {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: $black-subtittle;
  padding: 5% 10% 5% 10%;

  @media (max-width: $sm-breakpoint) {
    padding: 5% 10% 0;
  }
}

.content_box_centered .button_container {
  text-align: center;
  margin-top: 30px;

  @media (max-width: $sm-breakpoint) {
    margin-left: 10%;
    width: 80%;
    margin-top: 0;
  }
}

.alert-box-warning {
  display: flex;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.mb-10 {
  margin-bottom: 10px;
}

.alert-box-warning.text-left {
  text-align: left;
}

.mustard-link {
  color: $mustard-heavy;
  font-weight: bold;
}

.alert-content {
  margin-bottom: 0;
}

.alert-box-warning .icon {
  width: 10%;
}

.alert-box-warning .alert-content {
  padding-left: 10px;
  width: 90%;
}
